<template>
    <main class="page faq-page">
        <section class="clean-block clean-faq dark">
            <div class="container">
                <div class="block-heading">
                    <h2 class="text-info">FAQ</h2>
                    <p>Find common questions about Candice AI here.</p>
                </div>
                <div class="block-content">
                    <div class="faq-item">
                        <h4 class="question">What is Candice AI?</h4>
                        <div class="answer">
                            <p>Candice AI is a company that specialises in creating AI powered products and services.</p>
                        </div>
                    </div>
                    <div class="faq-item">
                        <h4 class="question">What is Candice AI for Games?</h4>
                        <div class="answer">
                            <p>Candice AI for Games is an AI plugin for games that provides several useful and powerful features to make your AI agents more intelligent.<br><br>Candice's features can be used to create simple bullets, to more complex agents
                                that can even effectively Pathfind, such as soldiers in an army. It is currently only available for the Unity3D game engine.</p>
                        </div>
                    </div>
                    <div class="faq-item">
                        <h4 class="question">What is Candice Smart Assistant?</h4>
                        <div class="answer">
                            <p>Candice Smart assistant is a voice activated virtual assistant designed for the blind and visually impaired. It uses image recognition to gain a visual map of the surroundings, and relays this information to the user through
                                text and audio.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
</template>

<script>
export default {
  name: "FaqComponent",
};
</script>