<template>
    <NavBarComponent />
    <ProductGames />
    <FooterComponent />
</template>
<script>
// @ is an alias to /src
import NavBarComponent from "@/components/NavBarComponent.vue";
import ProductGames from "@/components/ProductGames.vue";
import FooterComponent from "@/components/FooterComponent.vue";

export default {
  name: "Product",
  components: {
    NavBarComponent,
    ProductGames,
    FooterComponent,
  },
};
</script>