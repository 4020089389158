<template>
  <footer>
			&copy; Candice AI
			<div style="margin: 24px 0;">
				<a href="https://www.facebook.com/KandakeAI"><i class="fa fa-facebook-official footer-icon"></i></a>
				<a href="#"><i class="fa fa-twitter footer-icon"></i></a>
				<a href="#"><i class="fa fa-instagram footer-icon"></i></a>
				<a href="https://discord.gg/GUtK6EH"><i class="fa fa-hashtag footer-icon"></i></a> 
			</div>
		</footer>
</template>

<script>
export default {
  name: "FooterComponent",
    methods: {
        navigate: function (path) {
      //this.$router.push(path)
      this.$router.push(path).catch(err => {
        // Ignore the vuex err regarding  navigating to the page they are already on.
        if (
          err.name !== 'NavigationDuplicated' &&
          !err.message.includes('Avoided redundant navigation to current location')
        ) {
          // But print any other errors to the console
          console.log(err);
        }
      });
    },

    },


    
  
};
</script>

<style scoped>
	footer
	{
		margin-top: 4px;;
	}
</style>