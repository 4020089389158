<template>
  <NavBarComponent />
  <FaqComponent />
  <FooterComponent />
</template>

<script>
// @ is an alias to /src
import FaqComponent from "@/components/FaqComponent.vue";
import NavBarComponent from "@/components/NavBarComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";

export default {
  name: "Faq",
  components: {
    NavBarComponent,
    FaqComponent,
    FooterComponent,
  },
};
</script>