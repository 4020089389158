<template>
    <header id="header" class="header">
		<div id="navContent" class="nav-content">
			<img id="navImage" src="static/assets/img/Candice_Logo.png">
			<!-- Top Navigation Menu -->
			<div class="mobile-nav">
				  
				  <a href="javascript:void(0);" class="icon" v-on:click="mobileNav">
					<i class="fa fa-bars" style="color:white;"></i>
				  </a>
			</div>
			<div id="mobileNavContent" class="mobile-nav-content" style="display: none;">
					<a href="/">Home</a>
					<a href="#">Products</a>
					<a href="#">Contact</a>
			</div>
			<ul id="navList">
				<li><a href="/">Home</a></li>
				<li class="dropdown">
					<a href="#" class="dropbtn">Products</a>
					<div class="dropdown-content">
						<a href="/games" >AI for Games</a>
                        <!-- <a href="/tradebot" >TradeBot</a> -->
					</div>
				</li>
				<li><a href="/faq">FAQ</a></li>
				<!-- <li><a href="javascript:void(0)" id="subscribe">Subscribe</a></li> -->
                <li id="subscribe" style="display:none;"></li>
				
			</ul>
            
		</div>
			
    </header>
    <div class="c-modal" id="subscribeModal">
			<!-- Modal content -->
			<div class="c-modal-content">
				<div class="c-modal-header">
					<span class="close">×</span>
					<h2>Subscribe</h2>
				</div>
				<div class="c-modal-body">
					<form action="file:///E:/action_page.php">
						<div class="container">
							<h2>Subscribe to our Newsletter</h2>
							<p>Subscribe to our newsletter to receive regular emails about our company, products and everything gaming related.</p>
						</div>

						<div class="container" style="background-color:white">
							<input type="text" placeholder="Name" name="name" required="">
							<input type="text" placeholder="Email address" name="mail" required="">
							<label>
							<input type="checkbox" checked="checked" name="subscribe"> Daily Newsletter
							</label>
						</div>

						<div class="container">
							<input type="submit" value="Subscribe">
						</div>
					</form>
				</div>
				<div class="c-modal-footer">
					<h3>© Candice AI</h3>
				</div>
			</div>
		</div>
    
</template>

<script>
export default {
  name: "NavBarComponent",
    methods: {
        navigate: function (path) {
            //this.$router.push(path)
            this.$router.push(path).catch(err => {
                // Ignore the vuex err regarding  navigating to the page they are already on.
                if (
                err.name !== 'NavigationDuplicated' &&
                !err.message.includes('Avoided redundant navigation to current location')
                ) {
                // But print any other errors to the console
                console.log(err);
                }
            });
        },
        mobileNav: function()
        {
            var x = document.getElementById("mobileNavContent");
            if (x.style.display === "block") 
            {
                x.style.display = "none";
            } 
            else 
            {
                x.style.display = "block";
            }
            //window.alert(window.innerWidth);
        },

    },
    created() {
        
        
    },
    mounted() {
        this.$nextTick(function () 
        {
            var header = document.getElementById("header");
            var navContent = document.getElementById("navContent");
            // Get the modal
            var modal = document.getElementById("subscribeModal");
            // Get the button that opens the modal
            var btn = document.getElementById("subscribe");

            // Get the <span> element that closes the modal
            var span = document.getElementsByClassName("close")[0];

            // When the user clicks on the button, open the modal 
            btn.onclick = function() {
            //modal.style.display = "block";
            }

            // When the user clicks on <span> (x), close the modal
            span.onclick = function() {
            modal.style.display = "none";
            }

            // When the user clicks anywhere outside of the modal, close it
            window.onclick = function(event) {
                if (event.target == modal) {
                    modal.style.display = "none";
                }
            }
            if (header.style.position != "sticky")
            {
                var pixelOffset = 5;
                document.addEventListener("scroll", function() 
                {
                    if(window.scrollY >= pixelOffset)
                    {
                        //header.classList.add("header-sticky");
                        navContent.classList.add("nav-sticky");
                        
                    }
                    else
                    {
                        //header.classList.remove("header-sticky");
                        navContent.classList.remove("nav-sticky");
                    }
                    
                });
            }

            window.addEventListener("resize", function() 
            {
                //window.alert(window.innerWidth);
                var x = document.getElementById("mobileNavContent");
                    if (window.innerWidth > 600) 
                    {
                        x.style.display = "none";
                    } 
                
            });
        });
    }

    
  
};
</script>
<style scoped>

</style>