<template>
    <main class="page product-page">
        <section class="clean-block clean-product dark">
            <div class="container">
                <div class="block-heading">
                    <h2 class="text-info">AI for Games</h2>
                    <p>South Africa's leading game AI, and it's Free.</p>
                </div>
                <div class="block-content">
                    <div class="product-info">
                        <div class="row">
                            <div class="col-md-6">
                                    <!-- The expanding image container -->
                                    <div class="c-container">
                                        <!-- Close the image -->
                                        <!-- <span onclick="this.parentElement.style.display='none'" class="closebtn">&times;</span> -->

                                        <!-- Expanded image -->
                                        <img id="expandedImg" :src="expandedImageSrc" style="width:100%">

                                        <!-- Image text -->
                                        <div id="imgtext"></div>
                                    </div>
                                    <div class="row">
                                        <div style="align-self: flex-end;">
                                            <div v-for="img in images" :key="img.src" class="c-column">
                                            <img :src="img.src" alt="Nature" v-on:click="galleryClick(img.src);">
                                        </div>
                                        </div>
                                        
                                        
                                    </div>
                                
                            </div>
                            <div class="col-md-6">
                                <div class="info">
                                    <h3 style="width: 350px;">Candice AI for Games</h3>
                                    <div class="rating"><img src="static/assets/img/star.svg"><img src="static/assets/img/star.svg"><img src="static/assets/img/star.svg"><img src="static/assets/img/star.svg"><img src="static/assets/img/star.svg"></div>
                                    <div class="price">
                                        <h3>Free</h3><a href="https://assetstore.unity.com/packages/slug/148441" target="_blank"><img src="static/assets/img/unity-masterbrand-black.png" style="width: 30%;"></a><a href="https://github.com/Candice-AI/Candice-AI-for-Games-Unity" target="_blank"><img src="static/assets/img/GitHub_Logo.png" style="width: 20%;height: 53;"></a></div>
                                    <div
                                        class="summary">
                                        <p>Candice is an AI framework for games that provides several useful and powerful features to make your AI agents more intelligent.<br><br>Candice's features can be used to create simple bullets, to more complex agents
                                            that can even effectively Pathfind, such as soldiers in an army.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="product-info">
                    <div>
                        <ul class="nav nav-tabs" role="tablist" id="myTab">
                            <li class="nav-item" role="presentation"><a class="nav-link active" role="tab" data-toggle="tab" id="description-tab" href="#description">Description</a></li>
                            <li class="nav-item" role="presentation"><a class="nav-link" role="tab" data-toggle="tab" id="specifications-tabs" href="#specifications">Specifications</a></li>
                            <li class="nav-item" role="presentation"><a class="nav-link" role="tab" data-toggle="tab" id="addons-tabs" href="#addons">Addons</a></li>
                            <li class="nav-item" role="presentation"><a class="nav-link" role="tab" data-toggle="tab" id="tutorials-tabs" href="#tutorials">Tutorials</a></li>
                        </ul>
                        <div class="tab-content" id="myTabContent">
                            <div class="tab-pane active fade show description" role="tabpanel" id="description">
                                <p>Candice AI for Games is full of plentiful powerful and useful functionality in order to speed up your development process with the best possible AAA game AI.</p>
                                <div class="row">
                                    <div class="col-md-5">
                                        <figure class="figure"><img class="img-fluid figure-img" src="static/assets/img/PerceptionModule.png"></figure>
                                    </div>
                                    <div class="col-md-7">
                                        <h4>Perception System</h4>
                                        <p style="text-align: justify;">Candice features a state of the art perception system that utilises several techniques in order detect objects around.</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-5">
                                        <figure class="figure"><img class="img-fluid figure-img" src="static/assets/img/MovementModule.png"></figure>
                                    </div>
                                    <div class="col-md-7 right">
                                        <h4 style="text-align: left;">Movement System</h4>
                                        <p style="text-align: left;">Using various technologies, AI agents can move through 2D and 3D environments. In combination with pathfinding, these agents can navigate through complex areas such as mazes and battlefields.</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-5">
                                        <figure class="figure"><img class="img-fluid figure-img" src="static/assets/img/CombatModule.png"></figure>
                                    </div>
                                    <div class="col-md-7 right">
                                        <h4 style="text-align: left;">Combat System</h4>
                                        <p style="text-align: left;">AI agents can engage in combat by attacking and receiving damage accordingly. With support for healthbars and advanced detection, you will simulate real life combat.</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-5">
                                        <figure class="figure"><img class="img-fluid figure-img" src="static/assets/img/OtherModule.png"></figure>
                                    </div>
                                    <div class="col-md-7 right">
                                        <h4 style="text-align: left;">Decision Making and More</h4>
                                        <p style="text-align: left;">Behavior Trees allow AI agents to behave intelligently, providing a challenge for players. Other optimisations such as Threading allow for thousands of agents to function
                                            in the same scene while maintaining a decent frame rate.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade show specifications" role="tabpanel" id="specifications">
                                <div class="table-responsive table-bordered">
                                    <table class="table table-bordered">
                                        <tbody>
                                            <tr>
                                                <td class="stat">Release Date</td>
                                                <td>28 February 2022</td>
                                            </tr>
                                            <tr>
                                                <td class="stat">Latest Version</td>
                                                <td>1.0.1 Avocado</td>
                                            </tr>
                                            <tr>
                                                <td class="stat">Platforms</td>
                                                <td>Unity3D</td>
                                            </tr>
                                            <tr>
                                                <td class="stat">Copyright</td>
                                                <td>Candice AI</td>
                                            </tr>
                                            <tr>
                                                <td><strong>Developer</strong></td>
                                                <td><a href="https://www.candiceai.co.za" target="_blank">Candice AI<br></a></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="tab-pane fade show specifications" role="tabpanel" id="addons">
                                <div class="table-responsive table-bordered">
                                    <table class="table table-bordered">
                                        <tbody>
                                            <tr>
                                                <td class="stat">Behavior Designer</td>
                                                <td>Coming Soon</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="tab-pane fade show specifications" role="tabpanel" id="tutorials">
                                <div class="table-responsive table-bordered">
                                    <table class="table table-bordered">
                                        <tbody>
                                            <tr>
                                                <td class="stat">Getting Started</td>
                                                <td><iframe width="560" height="315" src="https://www.youtube.com/embed/cs1_1uOD3FY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td class="stat">Customizing Candice Part 1</td>
                                                <td><iframe width="560" height="315" src="https://www.youtube.com/embed/wqUT0vAWxhE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></td>
                                            </tr>
                                            <tr>
                                                <td class="stat">Customizing Candice Part 2</td>
                                                <td><iframe width="560" height="315" src="https://www.youtube.com/embed/JVTBxRMCpAY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></td>
                                            </tr>
                                            
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </section>
    </main>
</template>

<script>
// @ is an alias to /src

export default {
  name: "ProductGames",
  data: function () {
    return {
        expandedImageSrc: "",
        images: [
                {
                "src": "static/assets/img/Unity_Screenshot1.png",
                "title":""
                },
                {
                "src": "static/assets/img/Unity_Screenshot2.png",
                "title":""
                },
                {
                "src": "static/assets/img/Unity_Screenshot3.png",
                "title":""
                },
                {
                "src": "static/assets/img/Unity_Screenshot1.png",
                "title":""
                },
                ],
        }
  },
  components: {
  },
  mounted() {
      console.log("MIKE");
      this.expandedImageSrc = this.images[0].src;
      console.log(this.images[0].src);
      console.log(this.expandedImageSrc);
  },
  methods: {
      galleryClick: function(src){
            // Get the expanded image
            var expandImg = document.getElementById("expandedImg");
            // Get the image text
            var imgText = document.getElementById("imgtext");
            // Use the same src in the expanded image as the image being clicked on from the grid
            this.expandedImageSrc = src;
            // Use the value of the alt attribute of the clickable image as text inside the expanded image
            imgText.innerHTML = "";
            // Show the container element (hidden with CSS)
            expandImg.parentElement.style.display = "block";
            
      },
  }
};
</script>

<style scoped>
    .c-column {
  float: left;
  width: 25%;
  padding: 10px;
}

/* Style the images inside the grid */
.c-column img {
  opacity: 0.8;
  cursor: pointer;
  width:100%
}

.c-column img:hover {
  opacity: 1;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

/* The expanding image container (positioning is needed to position the close button and the text) */
.c-container {
  position: relative;
  display: block;
}

/* Expanding image text */
#imgtext {
  position: absolute;
  bottom: 15px;
  left: 15px;
  color: white;
  font-size: 20px;
}

/* Closable button inside the image */
.closebtn {
  position: absolute;
  top: 10px;
  right: 15px;
  color: white;
  font-size: 35px;
  cursor: pointer;
}

</style>