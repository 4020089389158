<template>
  <NavBarComponent />
  <HomeContent />  
  <FooterComponent />
  
</template>

<script>
// @ is an alias to /src
import HomeContent from "@/components/HomeContent.vue";
import NavBarComponent from "@/components/NavBarComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";

export default {
  name: "Home",
  components: {
    NavBarComponent,
    HomeContent,
    FooterComponent,
  },
};
</script>
<style>
</style>