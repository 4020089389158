<template>
  <div class="content">
        <div style="border-color: rgb(25,112,181);margin-top:32px;">
            <div class="text">
              <img src="static/assets/img/Candice%20Logo%20with%20text.png" style="width: 350px;z-index:-1;">
                <h2>for GAMES</h2>
                <p>South Africa's leading Game AI is here!</p><a v-on:click="navigate('/games')" href="#"><button class="btn btn-outline-light btn-lg" type="button">Learn More</button></a></div>
        </div>
    </div>
	

</template>

<script>
export default {
  name: "HomeContent",
   methods: {
        navigate: function (path) {
      //this.$router.push(path)
      this.$router.push(path).catch(err => {
        // Ignore the vuex err regarding  navigating to the page they are already on.
        if (
          err.name !== 'NavigationDuplicated' &&
          !err.message.includes('Avoided redundant navigation to current location')
        ) {
          // But print any other errors to the console
          console.log(err);
        }
      });
    },

    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
